.breadcrumb {
  margin-bottom: 0;
}

.main .container-fluid {
  padding: 0;
}

// select subscriber
.searchSubscriberIcon {
  font-size: 14px;
  color: $mediumGray;
  top: 11px;
  left: 9px;
  position: absolute;
  z-index: 99;
}

.switch {
  height: 22px;
  width: 36px;
}

.switch-slider::before {
  height: 16px;
  width: 16px;
}

.creatorView {
  background: #fff !important;
  border: 1px solid $theme-color !important;
  border-radius: 4px;
  color: $theme-color !important;
  letter-spacing: 0.5px;
  padding: 6px 10px;
  box-shadow: none !important;
  margin-left: 2px;
  margin-bottom: 8px;

  i {
    font-size: 13px;
    margin-right: 4px;
  }
}
