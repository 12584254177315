.MuiFormControl-marginNormal {
  * {
    font-family: "Poppins", sans-serif !important;
    outline: 0 !important;
  }
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: $mediumGray !important;
  letter-spacing: 0.5px !important;
  font-size: 15px !important;
  padding: 6px 10px !important;
  font-family: "Poppins", sans-serif !important;

  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: $white !important;
  background-clip: padding-box !important;
  border: 1px solid #e4e7ea !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  .MuiInputBase-root {
    input {
      margin-top: -2px !important;
    }
    button {
      padding: 0px !important;
      margin-top: -4px !important;
      margin-right: -4px !important;
    }
    &:before,
    &:after {
      border: none !important;
    }
  }
}

.MuiPickersToolbar-toolbar {
  background-color: #f5f7fa;
  background-image: linear-gradient(
    90deg,
    #ff409f 0%,
    $theme-color 100%
  ) !important;
}

.MuiPickersDay-daySelected {
  background-color: $theme-color !important;
  outline: 0 !important;
}

.MuiFormHelperText-root.Mui-error {
  color: rgba(255, 255, 255, 0) !important;
}

#videoOptionsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 17px 0 15px;
}

.fab-container {
  button {
    i {
      font-size: 18px;
    }
  }
}

// language selector
.fab-container {
  bottom: 20px !important;
  right: 30px !important;
  z-index: 99;
  position: absolute !important;
  margin: 0 !important;

  button {
    color: $white !important;
    box-shadow: none !important;
    font-size: 14px;
    background: $theme-color !important;
  }

  &:hover .fab-item:not(:last-child) {
    margin: 0 auto 12px !important;
  }
}

.fab-item:not(:last-child) {
  background: $theme-color !important;
  color: $white !important;
  letter-spacing: 0.5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.fab-item {
  margin: 0 auto !important;
}

.fab-item[tooltip]:before {
  font-family: "Poppins", sans-serif;
  font-weight: unset;
}

#language-switcher-container {
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 0rem 0rem !important;
    background-color: rgb(0, 0, 0) !important;
    cursor: unset !important;
  }

  .btn-lg,
  .btn-group-lg > .btn div {
    padding: 0rem 0rem !important;
    width: 100%;
    cursor: pointer !important;
    height: 100%;
  }
}

@media all and (max-width: 575.98px) {
  .fab-container {
    right: 13px !important;
    bottom: 14px !important;
  }
}
